import Logo from './assets/img/logo.png';

export let AmplifyComponent = {
  Header() {

    return (
      <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", paddingBottom: "10vh", paddingTop: "20vh" }}>
        <img className="companyLogo" src={Logo} />
      </div>
    );
  },

  Footer() {

    return (
      <>
        <br />
        <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", paddingBottom: "10vh", paddingTop: "20vh" }}>
        @Pyrrho Tech 2023
      </div>
      </>
    );
  },
} 