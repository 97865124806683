import axios from "axios";

export const postItem = async (path, dataToSend, token) => {
  let config = {
    headers: {
      'Authorization': `Bearer ${token}`,
      'Access-Control-Allow-Headers': `http://localhost:3000/`
    }
  }
  return axios.post(`${process.env.REACT_APP_API_BASE}${path}`, dataToSend, config)
}

export const getItem = (path, token) => {
  let config = {
    headers: {
      'Authorization': `Bearer ${token}`,
      'Access-Control-Allow-Headers': `http://localhost:3000/`
    }
  }
  return axios.get(`${process.env.REACT_APP_API_BASE}${path}`, config)
}


export const updateItem = (path, dataToSend, token) => {
  let config = {
    headers: {
      'Authorization': `Bearer ${token}`,
      'Access-Control-Allow-Headers': `http://localhost:3000/`
    }
  }
  return axios.put(`${process.env.REACT_APP_API_BASE}${path}`, dataToSend, config)

}


export const deleteItem = (path, token) => {
  let config = {
    headers: {
      'Authorization': `Bearer ${token}`,
      'Access-Control-Allow-Headers': `http://localhost:3000/`
    }
  }
  return axios.delete(`${process.env.REACT_APP_API_BASE}${path}`, config)

}

export const requestFileItem = (url, token) => {
  var config
  if (token === undefined) {
    config = {
      headers: {
        'Access-Control-Allow-Origin': '*'
      },
      responseType: 'blob'
    }
  } else {
    config = {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Access-Control-Allow-Origin': '*'
      },
      responseType: 'blob'
    }
  }
  // Actual call
  return axios.request(url, config)

}