import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import AdminLayout from "layouts/Admin/Admin.js";
import NonAdminLayout from "layouts/NonAdmin/NonAdmin.js";

import "assets/scss/black-dashboard-react.scss";
import "assets/demo/demo.css";
import "assets/css/nucleo-icons.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

import ThemeContextWrapper from "./components/ThemeWrapper/ThemeWrapper";
import BackgroundColorWrapper from "./components/BackgroundColorWrapper/BackgroundColorWrapper";

import { Amplify } from 'aws-amplify';
import { Authenticator, withAuthenticator } from '@aws-amplify/ui-react';
import awsconfig from './aws-exports';

import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

import { Blocks } from 'react-loader-spinner'
import '@aws-amplify/ui-react/styles.css';
import "assets/css/auth.css"
import { Auth } from 'aws-amplify';
import {AmplifyComponent} from './amplify-component'


export function DataExtractorApp() {
  const [isPresentResult, setIsPresentResult] = useState(false);
  const [IsLoaded, setIsLoaded] = useState(false);

  
  useEffect(() => {
    Auth.currentAuthenticatedUser().then(response => 
      {
        // if (response.signInUserSession.accessToken.payload["cognito:groups"].includes("SuperAdmin")) {
        //   setIsPresentResult(true)
        // }else{
        //   setIsPresentResult(false)
        // }
        setIsPresentResult(true)
      }
    ).catch(response => 
      setIsPresentResult(false)
    )
      
    setIsLoaded(true) 
  }, [])

  return ( <>
 
    {IsLoaded ? (
      <>
          {isPresentResult ? (
            <>
              <ThemeContextWrapper>
                <BackgroundColorWrapper>
                  <BrowserRouter>
                    <Switch>
                      <Route path="/" render={(props) => <AdminLayout {...props} />} />
                    </Switch>
                  </BrowserRouter>
                </BackgroundColorWrapper>
              </ThemeContextWrapper>
            </>)
            : (
              <ThemeContextWrapper>
                <BackgroundColorWrapper>
                  <BrowserRouter>
                    <Switch>
                      <Route path="/" render={(props) => <NonAdminLayout {...props} />} />
                    </Switch>
                  </BrowserRouter>
                </BackgroundColorWrapper>
              </ThemeContextWrapper>
            )
          }
        
      </>)
      : (
        <div style={{display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: '100vh'}}>
        <Blocks
            visible={true}
            height="80"
            width="80"
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperClass="blocks-wrapper"
          /></div>

      )
    }
    </>
    
  );
}

