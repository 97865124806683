import React, { useState, useEffect, useContext } from 'react';
import axios from "axios";
import { Blocks } from 'react-loader-spinner'
import NotificationAlert from "react-notification-alert";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import "../assets/css/black-dashboard-react.css"
import { getItem, deleteItem, postItem, requestFileItem } from "../services/api.svs";
import * as Sentry from "@sentry/react";
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col
} from "reactstrap";
import { Auth } from 'aws-amplify';
import { ThemeContext } from "../contexts/ThemeContext";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import FormHelperText from '@mui/material/FormHelperText';

function Extraction({ user }) {
  const theme = useContext(ThemeContext);
  const [IsLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([])
  const [customers, setCustomers] = useState([])
  const [runs, setRuns] = useState([])
  const [selectedOptions, setSelectedOptions] = React.useState([]);
  const [selectedCustomer, setSelectedCustomer] = React.useState();

  const [isCustomerSelected, setIsCustomerSelected] = useState(false);
  const [isActionSelected, setIsActionSelected] = useState(false);
  const [isStartDateEntered, setIsStartDateEntered] = useState(true);
  const [isEndDateEntered, setIsEndDateEntered] = useState(true);
  const [isDataValid, setIsDataValid] = useState(true);

  const validateDates = () => {
    if (!startDate || !endDate) {
      setIsStartDateEntered(!startDate);
      setIsEndDateEntered(!endDate);
      setIsDataValid(false);
      return false;
    }
  
    if (startDate > endDate) {
      setIsStartDateEntered(true);
      setIsEndDateEntered(true);
      setIsDataValid(false);
      return false;
    }
  
    setIsStartDateEntered(true);
    setIsEndDateEntered(true);
    setIsDataValid(true);
    return true;
  };

  const [options, setOptions] = useState([]);
  const [customerOptions, setCustomerOptions] = useState([]);

  const notificationAlertRef = React.useRef(null);
  const notify = (place, message, type) => {
    var options = {};
    options = {
      place: place,
      message: (
        message
      ),
      type: type,
      icon: "tim-icons icon-bell-55",
      autoDismiss: 7
    };
    notificationAlertRef.current.notificationAlert(options);
  };

  const handleExtract = async (e) => {
    e.preventDefault()
    setIsLoaded(false)
    // Check if the customer is selected
    if (!selectedCustomer) {
      setIsCustomerSelected(true);
    } else {
      setIsCustomerSelected(false);
    }

    // Check if at least one action is selected
    if (selectedOptions.length === 0) {
      setIsActionSelected(true);
    } else {
      setIsActionSelected(false);
    }

    if (selectedCustomer && selectedOptions.length > 0 && validateDates()) {
      let dataToSend = {
        "client_node": selectedCustomer.value.company_node,
        "client_name": selectedCustomer.value.company_name,
        "start_date": startDate,
        "end_date": endDate,
        "processes": selectedOptions.map(option => option.value)

      }
      console.log("Sending", dataToSend)
      const token = await (await Auth.currentSession()).getIdToken().getJwtToken()
      postItem(`extract`, dataToSend, token).then(resp => {
        let newArray = [...runs, resp.data];
        console.log("Data:", resp)
        console.log("arr", newArray)
        setRuns(newArray)
        let message = (<div>
          <div>
            Extract submitted
          </div>
        </div>);
        // window.location.reload()
        setIsLoaded(true)
        notify("tl", message, "success");

      }).catch(resp => {
        Sentry.captureException(resp);
        let message = (<div>
          <div>
            Could not retrieve details.
          </div>
        </div>);
        setIsLoaded(true)
        notify("tl", message, "danger");
      })
    } else {
      let message = (<div>
        <div>
          Please make sure your dates are correct
        </div>
      </div>);
      notify("tl", message, "warning");
      setIsLoaded(true);
    }
  };

  useEffect(() => {
    const getQuotes = async () => {
      const token = await (await Auth.currentSession()).getIdToken().getJwtToken()
      getItem('list', token).then(resp => {
        const textsToRemove = process.env.REACT_APP_GAAP_EXLUDES;
        const tmpList = resp.data
        const filteredList = tmpList.filter(item => !textsToRemove.includes(item.EXPORT));
        console.log(filteredList)
        setItems(resp.data)
        const mappedOptions = filteredList.map((item) => ({
          label: item.EXPORT,
          value: item.EXPORT,
        }));
        setOptions(mappedOptions);
        getItem('stores', token).then(resp => {
          const tmpList = resp.data
          console.log(resp.data)
          const mappedCOptions = tmpList.map((item) => ({
            label: `Comapny: ${item.COMPANY}; Region: ${item.REGION}; Branch: ${item.BRANCH}`,
            value:{ "company_name":  `${item.COMPANY}-${item.BRANCH}`, "company_node":  item.NODE },
          }));

          setCustomerOptions(mappedCOptions)
          setCustomers(resp.data)
          getItem('run', token).then(resp => {
            console.log(resp.data)
            setRuns(resp.data)
            setIsLoaded(true)
          }).catch(resp => {
            console.log(resp)
            setItems([])
            setIsLoaded(true)
            let message = (<div>
              <div>
                Error retrieving previous runs
              </div>
            </div>)
            notify("tl", message, "danger")
          })
        }).catch(resp => {
          console.log(resp)
          setItems([])
          setIsLoaded(true)
          let message = (<div>
            <div>
              Error retrieved Customers the data
            </div>
          </div>)
          notify("tl", message, "danger")
        })
      }).catch(resp => {
        console.log(resp)
        setItems([])
        setIsLoaded(true)
        let message = (<div>
          <div>
            Error retrieved Options the data
          </div>
        </div>)
        notify("tl", message, "danger")
      })
      //stop loading when data is fetched
    }
    getQuotes()
  }, [])


  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');


  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };


  const handleViewClick = async (path) => {
    console.log("Getting file", path)


    setIsLoaded(false)
    // Handle download manifest action
    await Auth.currentAuthenticatedUser();
    const session = await Auth.currentSession();
    const token = session.getIdToken().getJwtToken();
    // Need to add something to convert

    getItem(`/run/test?filePath=${path}`, token)
      .then((resp) => {
        setIsLoaded(true)
        requestFileItem(resp.data.url, undefined).then((response) => {
          // create file link in browser's memory
          const href = URL.createObjectURL(response.data);

          // create "a" HTML element with href to file & click
          const link = document.createElement('a');
          link.href = href;
          link.setAttribute('download', `test-extract.xlsx`); //or any other extension
          document.body.appendChild(link);
          link.click();

          // clean up "a" element & remove ObjectURL
          document.body.removeChild(link);
          URL.revokeObjectURL(href);

        }).catch((err) => {
          Sentry.captureException(err)
          setIsLoaded(true)
        })
      })
      .catch((error) => {
        setIsLoaded(true)
        Sentry.captureException(error)


      });
  };


  const handleRemoveClick = async (id) => {
    console.log(id)
    setIsLoaded(false)
    await Auth.currentAuthenticatedUser();
    const session = await Auth.currentSession();
    const token = session.getIdToken().getJwtToken();
    deleteItem(`/run/${id}`, token)
      .then((resp) => {
        setRuns((prevData) => {
          const updatedData = prevData.filter((item) => item.uuid !== id);
          return updatedData;
        });
        setIsLoaded(true)
        let message = (<div>
          <div>
            Extarct has been removed
          </div>
        </div>);
        // window.location.reload()
        notify("tl", message, "success");

      })
      .catch((error) => {
        setIsLoaded(true)
        Sentry.captureException(error)


      });
  };

  const columns = [
    { field: 'uuid', headerName: 'ID', width: 90 },
    {
      field: 'client_name',
      headerName: 'Client',
      flex: 1,
    },
    {
      field: 'start_date',
      headerName: 'Start Date',
      flex: 1,
    },
    {
      field: 'end_date',
      headerName: 'End Date',
      flex: 1,
    },
    {
      field: 'is_status',
      headerName: 'Status',
      flex: 1,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      flex: 1,
      renderCell: (params) => (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button
            className="nav-link d-none d-lg-block"
            style={{
              backgroundColor: "#20c997",
              color: "white", // Set text color to white
            }}
            onClick={() => handleViewClick(params.row.file_path)}
          >
            Download
          </Button>
          <Button
            className="nav-link d-none d-lg-block"
            style={{
              backgroundColor: "#20c997",
              color: "white", // Set text color to white
            }}
            onClick={() => handleRemoveClick(params.row.uuid)}
          >
            Remove
          </Button>
        </div>
      ),
    },
  ];




  const handleOptionChange = (event, newValue) => {
    setSelectedOptions(newValue);
  };


  // const options = items.map((item) => ({
  //   label: item.EXPORT,
  //   value: item.EXPORT,
  // }));


  // const customerOptions = customers.map((item) => ({
  //   label: `Comapny: ${item.COMPANY}; Region: ${item.REGION}; Branch: ${item.BRANCH}`,
  //   value: item.NODE,
  // }));

  const getRowId = (row) => row.uuid;

  return (
    <>
      <NotificationAlert ref={notificationAlertRef} />
      {IsLoaded ? (
        <>
          <>
            <div className="content">
              <Row>

                <Col md="12">
                  <Card className="card-plain">
                    <CardHeader>
                      <CardTitle tag="h4">Extractions</CardTitle>
                      <p className="category">Extractions run by team or to create a new extraction</p>
                    </CardHeader>
                    <CardBody>
                      <Form>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlSelect1">

                          <FormControl
                            fullWidth
                            error={isCustomerSelected}
                            variant="outlined"
                            className="mb-3"
                          >
                            <Autocomplete
                              disablePortal
                              id="combo-box-demo"
                              value={selectedCustomer} // Set the selected customer here
                              onChange={(event, newValue) => {
                                // Handle the change when the user selects a customer
                                setSelectedCustomer(newValue); // Assuming you have a state variable to store the selected customer
                              }}
                              options={customerOptions}
                              renderInput={(params) => <TextField {...params} label="Customer" />}
                            />  {isCustomerSelected && (
                              <FormHelperText error>Please select a customer.</FormHelperText>
                            )}
                          </FormControl>

                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlSelect1">

                          <FormControl
                            fullWidth
                            error={isActionSelected}
                            variant="outlined"
                            className="mb-3"
                          >
                            <Autocomplete
                              multiple
                              id="multi-select-autocomplete"
                              options={options}
                              value={selectedOptions}
                              onChange={handleOptionChange}
                              getOptionLabel={(option) => option.label}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  label="Select Data"
                                  placeholder="Options"
                                />
                              )}
                            />
                            {isActionSelected && (
                              <FormHelperText error>Please select at least one action.</FormHelperText>
                            )}
                          </FormControl>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlDate1">
                          <FormControl
                            fullWidth
                            error={!isStartDateEntered && !isDataValid}
                            variant="outlined"
                            className="mb-3"
                          >
                            <Form.Label>Start Date</Form.Label>
                            <Form.Control
                              type="date"
                              placeholder="Start Date"
                              onChange={handleStartDateChange}
                              value={startDate}
                            />
                            {!isStartDateEntered && (
                              <FormHelperText error>Please select a date.</FormHelperText>
                            )}

                            {!isDataValid && (
                              <FormHelperText error>Dates arent valid.</FormHelperText>
                            )}
                          </FormControl>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlDate2">
                          <FormControl
                            fullWidth
                            error={!isEndDateEntered || !isDataValid}
                            variant="outlined"
                            className="mb-3"
                          >
                            <Form.Label>End Date</Form.Label>
                            <Form.Control
                              type="date"
                              placeholder="End Date"
                              onChange={handleEndDateChange}
                              value={endDate}
                            />
                             {!isEndDateEntered && (
                              <FormHelperText error>Please select a date.</FormHelperText>
                            )}

                            {!isDataValid && (
                              <FormHelperText error>Dates arent valid.</FormHelperText>
                            )}
                          </FormControl>
                        </Form.Group>

                        <Button onClick={handleExtract}>Extract</Button>
                      </Form>
                      <br />
                      <h2>Previous extractions</h2>
                      <Box sx={{ height: 400, width: '100%' }}>
                        <DataGrid
                          rows={runs}
                          columns={columns}
                          getRowId={getRowId}
                          sx={{
                            [`& .MuiDataGrid-columnsPanel > div:first-child`]: {
                              display: "none"
                            }
                          }}
                          initialState={{
                            pagination: {
                              paginationModel: {
                                pageSize: 5,
                              },
                            },
                          }}
                          pageSizeOptions={[5]}
                          disableRowSelectionOnClick
                          editMode='false'
                        />
                      </Box>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>

          </>



        </>)
        : (
          <div className="content">
            <Row>

              <Col md="12">
                <Card className="card-plain">
                  <CardHeader>
                    <CardTitle tag="h4">Extractions</CardTitle>
                    <p className="category">Loading extractions</p>
                  </CardHeader>
                  <CardBody style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100vh'
                  }}>
                    <Blocks
                      visible={true}
                      height="80"
                      width="80"
                      ariaLabel="blocks-loading"
                      wrapperStyle={{}}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>


        )
      }

    </>
  );
}

export default Extraction;
